var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('base-material-tabs-card',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([{key:"campaign_information",fn:function(){return [_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.code'),"rules":{
              required: true,
              regex: /^[a-zA-Z0-9_-]+$/,
              uniqueValidator: !_vm.isEdit ? { resource: 'campaigns', field: 'codeEquals' } : false
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-text-input',{attrs:{"readonly":_vm.isEdit,"autofocus":!_vm.isEdit,"label":_vm.$t('integrations.fields.code') + ' *',"hint":_vm.$t('integrations.helper.campaign.code'),"source":"code","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.channel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-autocomplete-input',{attrs:{"readonly":_vm.isEdit,"label":_vm.$t('integrations.fields.channel') + ' *',"source":"channel","reference":"channels_globals_as_defaults","item-value":"id","search-query":"q","sort-by":['name'],"error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.type') + ' *',"source":"type","choices":_vm.CampaignTypeVariants,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-boolean-input',{attrs:{"label":_vm.$t('integrations.fields.all_day'),"source":"all_day","hint":_vm.$t('integrations.helper.campaign.all_day'),"persistent-hint":""},on:{"input":function($event){_vm.allDayActive = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"dateFrom","name":_vm.$t('integrations.fields.date_from'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
            var errors = ref.errors;
return [_c(_vm.allDayActive ? 'va-date-input' : 'va-date-time-input',_vm._b({tag:"component",on:{"input":validate}},'component',{
                label: _vm.$t('integrations.fields.date_from') + ' *',
                source: 'date_from',
                format: 'long',
                hint: _vm.$t(_vm.allDayActive ? 'integrations.helper.campaign.date_from' : 'integrations.helper.campaign.date_time_from'),
                'error-messages': errors
              },false))]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.date_to'),"rules":"required|dateNotBefore:@dateFrom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var validate = ref.validate;
              var errors = ref.errors;
return [_c(_vm.allDayActive ? 'va-date-input' : 'va-date-time-input',_vm._b({tag:"component",on:{"input":validate}},'component',{
                label: _vm.$t('integrations.fields.date_to') + ' *',
                source: 'date_to',
                format: 'long',
                hint: _vm.$t(_vm.allDayActive ? 'integrations.helper.campaign.date_until' : 'integrations.helper.campaign.date_time_until'),
                'error-messages': errors
              },false))]}}])})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.date_timezone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var validate = ref.validate;
              var errors = ref.errors;
return [_c('va-timezone-input',{attrs:{"label":_vm.$t('integrations.fields.date_timezone'),"source":"date_timezone","error-messages":errors,"value":_vm.timezoneOfUser},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-text-input',{attrs:{"label":_vm.$t('integrations.fields.description'),"source":"description","hint":_vm.$t('integrations.helper.campaign.description')}})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.fields.status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var validate = ref.validate;
              var errors = ref.errors;
return [_c('va-select-input',{attrs:{"label":_vm.$t('integrations.fields.status') + ' *',"hint":_vm.$t('integrations.helper.campaign.enabled'),"source":"status","choices":_vm.CampaignStatusVariants,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('va-code-input',{attrs:{"label":_vm.$t('integrations.fields.constraint'),"source":"constraint"}})],1)],1),_c('v-row',[_c('v-col',[_c('va-array-input',{attrs:{"label":_vm.$t('integrations.fields.attributes'),"hint":_vm.$t('integrations.helper.company_address.attributes'),"source":"attributes_key_value"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('integrations.attributes.key'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var validate = ref.validate;
              var errors = ref.errors;
return [_c('va-combobox-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.key') + ' *',"source":"key","choices":_vm.CampaignAttributeKeys,"item-text":"name","item-value":"id","error-messages":errors},on:{"input":validate}},'va-combobox-input',props,false))]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('va-text-input',_vm._b({attrs:{"label":_vm.$t('integrations.attributes.value'),"source":"value"}},'va-text-input',props,false))],1)],1)]}}])})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('va-save-button'),_c('va-cancel-button',{staticClass:"ml-2"})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }